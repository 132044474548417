/**
 * @file api.js is used for API calls in the React app
 * @author Akshay Bhoite
 * @category File
 * @version 0.1.0
 */

import axios from 'axios';
import * as actions from './actionTypes';
import {toastr} from "react-redux-toastr";
import {useNavigate} from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';

/**
 * POST request to retrieve JIRA issues based on user input.
 * @endpoint "/jira-issues" Endpoint in Express that handles the JIRA API requests.
 * @param projectKey The key of the JIRA project to query.
 * @param issueType Type of the issues to retrieve (e.g., Bug, Task).
 * @param searchString A string included in the issue's summary.
 *
 * @return JSON object containing JIRA issues.
 */
const postJiraIssue = (id, projectKey, summary, jiraIssueType, jiraSearchString, navigate, name = "", email = "", phone = "", helpType = "", description = "") => async (dispatch, getState) => {

    dispatch({ type: actions.POST_JIRA_ISSUE_REQUEST })

    const config = {
        params: {
            customerId: id
        }
    }

    try {
        const response = await axios.post(
            '/jira/issue',  // Make sure this matches the route in your Express app
            { projectKey, summary, jiraIssueType, jiraSearchString, name ,email, phone, helpType, description},config
        )

        dispatch({ type: actions.POST_JIRA_ISSUE_SUCCESS, payload: response.data })

        if(response.status === 200){
            toast.success('Successfully created subscription cancellation support request.');
            navigate('/admin-requests');
        }else if(response.status !== 200){
            const {message} = response.data;
            toast.error( message );
        }else{
            toast.error('Failed to create Support Request, please try again!');
        }
    } catch (error) {
        dispatch({ type: actions.POST_JIRA_ISSUE_FAILURE, error })
    }
}

export default postJiraIssue;
